<template>
  <div>
    <v-container>
      <page-title title="Return Material Criteria">
        <template slot="action">
          <v-btn color="primary" @click.stop="showFormAdd()" v-if="modulePermission('rmcriteria', 'create')">Add</v-btn>
        </template>
      </page-title>

      <v-card class="mb-2">
        <v-tabs v-model="tab" background-color="primary" dark fixed-tabs height="60" show-arrows>
          <v-tab v-for="item in tabItems" :key="item.tab">{{ item.tab }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItems" :key="item.tab">
            <div class="px-2 py-6 d-flex flex-row">
              <v-text-field class="mb-2 mr-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
              <v-btn icon @click="getData(true)">
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </div>
            <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="true" class="mb-3 my-0">
              <template v-slot:[`item`]="{ item }">
                <v-card elevation="0" class="mx-1">
                  <v-card-text class="px-2">
                    <div class="d-flex flex-row">
                      <a :href="item.picture" target="_blank" v-if="!isMobile" :title="item.title">
                        <v-img height="200" width="200" :src="resourceUrl(item.picture)"></v-img>
                      </a>
                      <div class="px-2">
                        <v-card-title class="px-0 pt-0">{{ item.title }}</v-card-title>
                        <v-card-subtitle class="px-0 pt-0">{{ item.rm_criteria }} | {{ item.item_category }}</v-card-subtitle>
                        <p>{{ item.condition }}</p>
                        <v-btn v-if="modulePermission('rmcriteria', 'modify')" small rounded color="primary" class="ma-1" @click="showFormEdit(item.id)"><v-icon>mdi-pencil</v-icon> Edit</v-btn>
                        <v-btn v-if="modulePermission('rmcriteria', 'delete')" small rounded color="error" class="ma-1" @click="confirmDelete(item)"><v-icon>mdi-delete</v-icon> Delete</v-btn>
                      </div>

                      <a :href="item.picture" target="_blank" v-if="isMobile">
                        <v-img height="150" width="150" :src="item.picture"></v-img>
                      </a>
                    </div>
                  </v-card-text>
                </v-card>
              </template>
              <v-alert slot="no-results" :value="true" border="left" colored-border type="error" elevation="2">Your search for "{{ search }}" found no results.</v-alert>s
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>

    <v-form ref="refFormAdd" v-model="formAdd.formValid" lazy-validation>
      <v-dialog v-model="formAdd.dialog" max-width="800">
        <v-card>
          <v-toolbar elevation="0" dense>
            <v-toolbar-title>Add New</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="formAdd.dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <v-row>
              <v-col md="8">
                <v-text-field dense type="text" :rules="formAdd.formRules.title" v-model="formAdd.formData.title" label="Title" outlined></v-text-field>
                <v-textarea dense :rules="formAdd.formRules.condition" v-model="formAdd.formData.condition" label="Condition" outlined></v-textarea>
              </v-col>
              <v-col md="4" style="border-left: 1px solid #ddd">
                <v-select dense :rules="formAdd.formRules.rm_criteria" v-model="formAdd.formData.rm_criteria" :items="$store.state.helper.listRmCriteria" label="RM Criteria" outlined></v-select>
                <v-select dense :rules="formAdd.formRules.item_category" v-model="formAdd.formData.item_category" :items="$store.state.helper.listItemCategory" label="Item Category" outlined></v-select>
                <v-text-field dense type="text" :rules="formAdd.formRules.picture" v-model="formAdd.formData.picture" label="Picture" outlined append-icon="mdi-upload" readonly @dblclick="$refs.refFileUpload.click()" @click:append="$refs.refFileUpload.click()"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mt-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.stop="saveData()" :disabled="!formAdd.formValid">
              Save
            </v-btn>
            <v-btn color="light" @click.stop="formAdd.dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <input type="file" ref="refFileUpload" @change="handleFileUpload()" style="display:none" accept="image/*">

    <v-form ref="refFormEdit" v-model="formEdit.formValid" lazy-validation>
      <v-dialog v-model="formEdit.dialog" max-width="800">
        <v-card>
          <v-toolbar elevation="0" dense>
            <v-toolbar-title>{{ formEdit.formData.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="formEdit.dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <v-row>
              <v-col md="8">
                <v-text-field dense type="text" :rules="formEdit.formRules.title" v-model="formEdit.formData.title" label="Title" outlined></v-text-field>
                <v-textarea dense :rules="formEdit.formRules.condition" v-model="formEdit.formData.condition" label="Condition" outlined></v-textarea>
              </v-col>
              <v-col md="4" style="border-left: 1px solid #ddd">
                <v-select dense :rules="formEdit.formRules.rm_criteria" v-model="formEdit.formData.rm_criteria" :items="$store.state.helper.listRmCriteria" label="RM Criteria" outlined></v-select>
                <v-select dense :rules="formEdit.formRules.item_category" v-model="formEdit.formData.item_category" :items="$store.state.helper.listItemCategory" label="Item Category" outlined></v-select>
                <v-text-field dense type="text" :rules="formEdit.formRules.picture" v-model="formEdit.formData.picture" label="Picture" outlined append-icon="mdi-upload" readonly @dblclick="$refs.refFileUpload2.click()" @click:append="$refs.refFileUpload2.click()"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mt-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.stop="updateData()" :disabled="!formEdit.formValid">
              Save
            </v-btn>
            <v-btn color="light" @click.stop="formEdit.dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>


    <v-form ref="refFormDelete" v-model="formDelete.formValid" lazy-validation>
      <v-dialog v-model="formDelete.dialog" max-width="350">
        <v-card>
          <v-toolbar elevation="0" dense>
            <v-toolbar-title>Confirm</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="formDelete.dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider class="mb-4"></v-divider>
          <v-card-text>
            <p>Delete <b>"{{ formDelete.formData.title }}"</b>?</p>
          </v-card-text>
          <v-divider class="mt-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click.stop="deleteData()" :disabled="!formDelete.formValid">
              Delete
            </v-btn>
            <v-btn color="light" @click.stop="formDelete.dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <input type="file" ref="refFileUpload2" @change="handleFileUpload2()" style="display:none" accept="image/*">
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: null,
      search: "",

      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "Item", value: "id" },
          { text: "Item Category.", value: "item_category" },
          { text: "Title", value: "title" },
          { text: "Condition", value: "condition" },
          { text: "picture", value: "picture" },
        ],
      },

      tab: null,
      tabItems: [
        { tab: "Repair" },
        { tab: "Maintenance" },
        { tab: "Reject" },
        { tab: "Good" },
      ],


      formAdd: {
        dialog: null,
        formData: {
          id: 0,
          picture: '',
          rm_criteria: '',
          item_category: '',
          title: '',
          condition: '',
        },
        formRules: {
          picture: [v => !!v || 'Picture is required'],
          rm_criteria: [v => !!v || 'RM Criteria is required'],
          item_category: [v => !!v || 'Item Category is required'],
          title: [v => !!v || 'Title is required'],
          condition: [v => !!v || 'Condition is required'],
        },
        formErrors: {},
        formValid: true,
      },
      formEdit: {
        dialog: null,
        formData: {
          id: '',
          picture: '',
          rm_criteria: '',
          item_category: '',
          title: '',
          condition: '',
        },
        formRules: {
          picture: [v => !!v || 'Picture is required'],
          rm_criteria: [v => !!v || 'RM Criteria is required'],
          item_category: [v => !!v || 'Item Category is required'],
          title: [v => !!v || 'Title is required'],
          condition: [v => !!v || 'Condition is required'],
        },
        formErrors: {},
        formValid: true,
      },
      formDelete: {
        dialog: null,
        formData: {
          id: '',
          picture: '',
          rm_criteria: '',
          item_category: '',
          title: '',
          condition: '',
        },
        formRules: {
          picture: [v => !!v || 'Picture is required'],
          rm_criteria: [v => !!v || 'RM Criteria is required'],
          item_category: [v => !!v || 'Item Category is required'],
          title: [v => !!v || 'Title is required'],
          condition: [v => !!v || 'Condition is required'],
        },
        formErrors: {},
        formValid: true,
      }
    };
  },

  computed: {
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    tab(n, o) {
      this.getData();
    },
  },

  methods: {
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("GET", "rmcriteria/data");

      this.datatable.loading = true;
      console.log(this.tabItems[this.tab].tab);
      var params = {
        rm_criteria: this.tabItems[this.tab].tab,
      };
      this.$axios
        .get("rmcriteria/data", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data;
          } else {
            this.showAlert(res.data.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },

    showFormAdd() {
      this.$refs.refFormAdd.reset()
      this.formAdd.dialog = true
    },

    handleFileUpload() {
      this.image = this.$refs.refFileUpload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("image", this.image);
      this.$axios
        .post("rmcriteria/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data
          if (res.data.status) {
            this.formAdd.formData.picture = resData.data.src
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          console.log("FAILURE!!");
        });
    },

    async saveData() {
      this.$refs.refFormAdd.validate()
      if (this.formAdd.formValid) {
        var formData = new FormData();
        formData.append('picture', this.formAdd.formData.picture)
        formData.append('rm_criteria', this.formAdd.formData.rm_criteria)
        formData.append('item_category', this.formAdd.formData.item_category)
        formData.append('title', this.formAdd.formData.title)
        formData.append('condition', this.formAdd.formData.condition)
        await this.$axios.post('rmcriteria/save', formData)
          .then((res) => {
            this.formAdd.dialog = false
            this.showLoadingOverlay(false);
            this.$refs.refFormAdd.reset()
            var resData = res.data
            if (res.data.status) {
              this.getData(true);
              this.showAlert(resData.status, resData.message);
            } else {
              this.showAlert(resData.status, resData.message);
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            this.axiosErrorHandler(error);
            this.formAdd.dialog = false
            this.showLoadingOverlay(false);
            this.$refs.refFormAdd.reset()
          });
      }
    },

    handleFileUpload2() {
      this.image = this.$refs.refFileUpload2.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("image", this.image);
      this.$axios
        .post("rmcriteria/upload-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data
          if (res.data.status) {
            this.formEdit.formData.picture = resData.data.src
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    showFormEdit(id, refresh) {
      if (refresh) this.AxiosStorageRemove("GET", "rmcriteria/data-detail");

      this.formEdit.dialog = false
      this.formEdit.formData = {
        id: '',
        picture: '',
        rm_criteria: '',
        item_category: '',
        title: '',
        condition: '',
      }

      var params = {
        id: id,
      };
      this.$axios
        .get("rmcriteria/data-detail", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.formEdit.formData.id = resData.data.rmcriteria.id;
            this.formEdit.formData.picture = resData.data.rmcriteria.picture;
            this.formEdit.formData.rm_criteria = resData.data.rmcriteria.rm_criteria;
            this.formEdit.formData.item_category = resData.data.rmcriteria.item_category;
            this.formEdit.formData.title = resData.data.rmcriteria.title;
            this.formEdit.formData.condition = resData.data.rmcriteria.condition;
            this.formEdit.dialog = true
          } else {
            this.showAlert(res.data.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },


    async updateData() {
      this.$refs.refFormEdit.validate()
      if (this.formEdit.formValid) {
        var formData = new FormData();
        formData.append('id', this.formEdit.formData.id)
        formData.append('picture', this.formEdit.formData.picture)
        formData.append('rm_criteria', this.formEdit.formData.rm_criteria)
        formData.append('item_category', this.formEdit.formData.item_category)
        formData.append('title', this.formEdit.formData.title)
        formData.append('condition', this.formEdit.formData.condition)
        await this.$axios.post('rmcriteria/update', formData)
          .then((res) => {
            this.formEdit.dialog = false
            this.showLoadingOverlay(false);
            this.$refs.refFormEdit.reset()
            var resData = res.data
            if (res.data.status) {
              this.getData(true);
              this.AxiosStorageRemove("GET", "rmcriteria/data-detail");
              this.showAlert(resData.status, resData.message);
            } else {
              this.showAlert(resData.status, resData.message);
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            this.formEdit.dialog = false
            this.showLoadingOverlay(false);
            this.$refs.refFormEdit.reset()
            this.axiosErrorHandler(error);
          });
      }
    },

    confirmDelete(item) {
      this.formDelete.dialog = true
      this.formDelete.formData = item

    },



    async deleteData() {
      this.$refs.refFormEdit.validate()
      if (this.formDelete.formValid) {
        var formData = new FormData();
        formData.append('id', this.formDelete.formData.id)
        await this.$axios.post('rmcriteria/delete', formData)
          .then((res) => {
            this.formDelete.dialog = false
            this.showLoadingOverlay(false);
            this.$refs.refFormDelete.reset()
            var resData = res.data
            if (res.data.status) {
              this.getData(true);
              this.AxiosStorageRemove("GET", "rmcriteria/data-detail");
              this.showAlert(resData.status, resData.message);
            } else {
              this.showAlert(resData.status, resData.message);
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            this.formDelete.dialog = false
            this.showLoadingOverlay(false);
            this.$refs.refFormDelete.reset()
            this.axiosErrorHandler(error);
          });
      }
    },
  },


  mounted() {
    this.requiredLogin();
    this.modulePermission('rmcriteria', 'view', true)
    this.getData();
  },
};
</script>